export const environment = {
  production: true,
  baseUrl: 'https://isso-assets.finra.org/reportcenter-isso/',
  ddwaBaseUrl: 'https://api-isso.ddwa.finra.org/',
  onPremRcUrl: 'https://reportcenter.finra.org/scrc/index.do',
  ewsLogoutUrl: '',
  ewsExpirationTimeUrl: '',
  ewsLoginUrl: '',
  isEwsReportCenter: 'false',
  issoBaseUrl: 'https://isso-assets.finra.org/reportcenter-isso/',

  notificationsAnnouncementUrl: 'https://notifications-internal.dxtntfcn.finra.org',
  beastClickStreamUrl: 'https://beast-api.finra.org/events',
  beastDetailsFormatVersion: '1.0.0',
  beastEnvironment: 'prod',
  env: 'prodisso',
  isCloudfront: 'true',
  fipAuthPageUrl: 'https://isso.fip.finra.org/fip/',
  fipApiUrl: 'https://isso.fip.finra.org/fip/rest/sessions/expirationTime',
};
